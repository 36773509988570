<template>
  <v-main :class="marging" class="fill-height background">
    <div id="core-view" class="pl-8 pr-8 fill-height background">
      <v-row class="mx-3 mb-2 mt-8">
        <h2 class="float-left black_text--text title-compact text-header-5">
          {{ title }}
        </h2>
      </v-row>
      <v-row class="ma-3">
        <v-divider></v-divider>
      </v-row>
      <v-slide-y-transition mode="out-in">
        <router-view />
      </v-slide-y-transition>
    </div>
  </v-main>
</template>

<script>
export default {
  name: "CoreView",
  props: {
    marging: String
  },
  data() {
    return {
      title: null
    };
  },
  watch: {
    $route(val) {
      this.title = val.name;
    }
  },
  mounted() {
    this.title = this.$route.name;
  },
  metaInfo() {
    return {
      title: process.env.VUE_APP_TITLE
      /* title: 'Fast Data' */
    };
  }
};
</script>
